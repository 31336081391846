import styled from "styled-components"

import CocaColaRoundedSVG from "../../SVG/CocaColaRoundedSVG"

export const Container = styled.div`
  width: 100%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
`

export const Content = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  margin: 0 50px;
  flex-direction: column;
  justify-content: flex-start;
`

export const TitleBox = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  strong {
    letter-spacing: 1px;
    font-size: 24px;
    color: #005396;
  }
`

export const BrandsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 400px) {
    grid-template-columns: 1fr 1fr;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
    svg {
      width: 120px;
      height: 120px;
    }

    img {
      width: 120px;
      height: 120px
    }

    @media (max-width: 600px) {
      svg {
      width: 90px;
      height: 90px;
    }

    img {
      width: 90px;
      height: 90px
    }
    }
  }
`

export const CocaColaRounded = styled(CocaColaRoundedSVG)`
  @media (max-width: 900px) {
    height: 90px;
    width: 90px;
  }
`
