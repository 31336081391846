import React, { useState } from "react"
import { useMobileScreen } from '../../../hooks/screen';

import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md"
import { Container, Content, NextButton, MainBanner } from "./styles"

import banner from "../../../data/b.json"

import Banner from "./Banner"

export default function Banners() {
  const [currentBanner, setCurrentBanner] = useState(0)

  const { isSmallScreen } = useMobileScreen()

  const b = banner.banners

  const bannersList = b[currentBanner]

  const { color, title, name, image, body, textColor, phrase } = bannersList

  return (
    <Container color={color} isMobile={isSmallScreen}>
      <Content isMobile={isSmallScreen}>
        {isSmallScreen ? (
          <></>
        ) : (
          <NextButton
            type="text"
            onClick={() =>
              setCurrentBanner(
                currentBanner > 0 ? currentBanner - 1 : b.length - 1
              )
            }
            isMobile={isSmallScreen}
          >
            <MdKeyboardArrowLeft color={textColor} size={64} />
          </NextButton>
        )}

        <MainBanner background={color}>
          <Banner
            title={title}
            name={name}
            color={color}
            image={image}
            body={body}
            textColor={textColor}
            phrase={phrase}
            isMobile={isSmallScreen}
          />
        </MainBanner>

        {isSmallScreen ? (
          <div>
            <NextButton
              type="text"
              onClick={() =>
                setCurrentBanner(
                  currentBanner > 0 ? currentBanner - 1 : b.length - 1
                )
              }
              isMobile={isSmallScreen}
            >
              <MdKeyboardArrowLeft color={textColor} size={64} />
            </NextButton>
            <NextButton
              type="text"
              onClick={() =>
                setCurrentBanner(
                  currentBanner < b.length - 1 ? currentBanner + 1 : 0
                )
              }
              isMobile={isSmallScreen}
            >
              <MdKeyboardArrowRight color={textColor} size={64} />
            </NextButton>
          </div>
        ) : (
          <NextButton
            type="text"
            onClick={() =>
              setCurrentBanner(
                currentBanner < b.length - 1 ? currentBanner + 1 : 0
              )
            }
            isMobile={isSmallScreen}
          >
            <MdKeyboardArrowRight color={textColor} size={64} />
          </NextButton>
        )}
      </Content>
    </Container>
  )
}
