import React from "react"

import { useMobileScreen } from '../../../hooks/screen';

import { Container, Content, TitleBox, BrandsBox } from "./styles"
import CocaColaRoundedSVG from "../../SVG/CocaColaRoundedSVG"
import SchweppesRoundedSVG from "../../SVG/SchweppesRoundedSVG"
import SpriteRoundedSVG from "../../SVG/SpriteRoundedSVG"
import FantaRoundedSVG from "../../SVG/FantaRoundedSVG"
import MonsterRoundedSVG from "../../SVG/MonsterRoundedSVG"

const Portfolio : React.FC = () => {
  const { isSmallScreen } = useMobileScreen()

  return (
    <Container id="portfolio">
      <Content>
        <TitleBox>
          <strong>Nossas Marcas</strong>
        </TitleBox>
        <BrandsBox>
          <a
            href="https://www.cocacola.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CocaColaRoundedSVG/>
          </a>
          <a
            href="https://www.heineken.com/br/we-are-heineken/our-beer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/heineken.png"
              alt="heineken"
            />
          </a>
          <a
            href="https://www.delvalle.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/delValle.png"
              alt="delValle"
            />
          </a>
          <a
            href="https://loja.cocacolabrasil.com.br/marca-schweppes"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SchweppesRoundedSVG />
          </a>
          <a
            href="https://www.cocacolabrasil.com.br/marcas/sprite/sprite"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SpriteRoundedSVG />
          </a>
          <a
            href="https://play.fanta.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FantaRoundedSVG />
          </a>
          <a
            href="https://www.powerade.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/powerade.png"
              alt="powerade"
            />
          </a>
          <a
            href="https://loja.cocacolabrasil.com.br/marca-ades"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/ades.png"
              alt="ades"
            />
          </a>
          <a
            href="https://www.amstelbrasil.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/amstel.png"
              alt="amstel"
            />
          </a>
          <a
            href="https://www.sol.com/br/br/nossa-cerveja"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/solpremium.png"
              alt="sol"
            />
          </a>
          <a
            href="https://kaiser.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/kaiser.jpg"
              alt="kaiser"
            />
          </a>
          <a
            href="https://www.monsterenergy.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MonsterRoundedSVG />
          </a>
          <a
            href="https://www.cocacolabrasil.com.br/marcas/kuat/kuat"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/kuat.png"
              alt="kuat"
            />
          </a>
          <a
            href="http://www.aguamineralcrystal.com.br/pt/home/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/crystal.png"
              alt="agua crystal"
            />
          </a>
        </BrandsBox>
      </Content>
    </Container>
  )
}

export default Portfolio;