import React from "react"

import { Container, Icons, ButtonMail, Content } from "./styles"

import { useMobileScreen } from '../../hooks/screen';

const HeaderTop = () => {

  const { isSmallScreen } = useMobileScreen()
  

  return (
    <Container>
      <Content>
        <Icons>
          <a
            href="https://api.whatsapp.com/send?phone=558436452416"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/whatsappIcon1.png"
              alt="whatsapp"
              style={{
                width: isSmallScreen ? 32 : 36,
                height: isSmallScreen ? 32 : 36,
              }}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/vvc-distribuidora/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/linkedinIcon.png"
              alt="linkedin"
              style={{
                width: isSmallScreen ? 32 : 36,
                height: isSmallScreen ? 32 : 36,
              }}
            />
          </a>
          <a
            href="https://www.facebook.com/VVCDistribuidora/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/facebookIcon.png"
              alt="facebook"
              style={{
                width: isSmallScreen ? 32 : 36,
                height: isSmallScreen ? 32 : 36,
              }}
            />
          </a>
          <a
            href="https://t.me/vvcdistribuidora"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/telegramIcon.png"
              alt="telegram"
              style={{
                width: isSmallScreen ? 32 : 36,
                height: isSmallScreen ? 32 : 36,
              }}
            />
          </a>
        </Icons>

        {isSmallScreen ? null : (
          <ButtonMail
            href="https://webmail-seguro.com.br/vvcdistribuidora.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>WEBMAIL</p>
          </ButtonMail>
        )}
      </Content>
    </Container>
  )
}

export default HeaderTop;
