import React from "react"
import { Helmet } from "react-helmet"

import { Container, GlobalStyle } from "./styles"

import HeaderTop from "../HeaderTop"
import Header from "../Header"
import Footer from "../Footer"


const DefaultLayout: React.FC = ({ children }) => {

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width" />
        <meta name="format-detection" content="telephone=no"></meta>
        <meta
          name="google-site-verification"
          content="FXHLL4x__Yu_iSftrSStD5bPV2HgWhGnhxsV2Fe7_FM"
        />
        <meta
          name="keywords"
          content="coca-cola heineken amstel fanta kaiser sol premium refrigerantes agua sucos energeticos cervejas destilados"
        />
        <meta
          name="description"
          content="A VVC é uma distribuidora autorizada da coca-cola e heineken, que distribui no RN diversos produtos como: cervejas, refrigerantes, sucos, aguas e energeticos"
        />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="Portuguese" />
        <title>
          VVC Distribuidora - Sua distribuidora Coca-Cola e Heineken
        </title>
      </Helmet>
      <Container >
        <GlobalStyle />
        <HeaderTop />
        <Header />
        {children}
        <Footer />
      </Container>
    </>
  )
}

export default DefaultLayout


