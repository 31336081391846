import styled from "styled-components"

export const Container = styled.div`
  background-color: #f3f3f3;
  position: fixed;
  top: 0;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  z-index: 3;
`
export const Icons = styled.div`
  display: flex;
  img {
    margin-right: 5px;
  }
`
export const ButtonMail = styled.a`
  width: 100px;
  height: 32px;
  background-color: #fff;
  border: #e61c2b 1px solid;
  color: #e61c2b;
  font-weight: bolder;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  &:hover {
    background-color: #e61c2b;
    color: #fff;
  }
`
export const Content = styled.div`
  max-width: 1300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
