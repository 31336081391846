import React from "react"
import PropTypes from "prop-types"

export default function SpriteRoundedSVG({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60" cy="60" r="60" fill="#00A950" />
      <path
        d="M55.693 47.4213C54.7115 46.6742 54.1255 45.9417 54.3745 45.6487C54.6529 45.341 55.3414 45.7219 56.3815 45.8977C61.7874 46.7328 66.2995 45.5608 69.8448 43.627C73.4047 41.6932 76.8328 38.5435 79.0596 33.5479C79.499 32.581 79.543 31.7899 79.9532 31.7313C80.3341 31.6874 80.6417 32.5664 80.7296 33.7969C81.2424 40.8582 76.2907 46.1907 72.3939 48.3003C68.497 50.4245 61.3332 51.6844 55.693 47.4213Z"
        fill="#6CBF5B"
      />
      <path
        d="M55.9127 45.0919C54.1254 44.8282 52.9241 44.5206 52.6604 44.4766C52.2941 44.4327 52.1623 44.6817 52.4553 44.9308C52.7922 45.1945 53.1585 45.1798 53.1878 45.4582C53.2024 45.6486 53.0559 45.7805 52.8069 45.8098C52.5139 45.8537 52.133 45.7219 51.8986 45.5607C50.7413 44.755 50.9757 43.4365 52.1623 42.7187C52.9827 42.2206 53.1438 41.1804 53.2464 40.4333C53.891 35.467 57.8172 30.9255 61.7873 28.772C65.7574 26.6185 71.6906 25.7834 76.2027 27.9516C76.8913 28.2739 77.8435 28.7134 78.7079 28.2885C79.9678 27.6732 81.1983 28.2006 81.2423 29.607C81.2569 29.8854 81.1397 30.2809 80.9493 30.5153C80.7881 30.7058 80.583 30.7497 80.4512 30.6472C80.2168 30.4567 80.4365 30.1637 80.3926 29.7389C80.3486 29.358 80.0703 29.3287 79.9091 29.6656C79.7919 29.9147 79.4111 31.0867 78.6493 32.7275C76.3932 37.6498 73.1995 40.5358 69.3026 42.6454C65.4351 44.755 61.2745 45.883 55.9127 45.0919Z"
        fill="#F8CF29"
      />
      <path
        d="M86.9851 23C85.4761 25.7835 83.8353 28.4058 82.048 30.8377C82.1506 31.2918 82.2385 31.8339 82.3117 32.4638C83.4544 30.9256 84.5385 29.2701 85.564 27.4975C86.3844 36.6537 91.8488 43.8761 108.154 38.1626C98.5438 57.2514 101.84 64.9719 102.514 68.9566C65.3765 69.9382 48.7929 83.4893 35.8717 93.7296C30.2608 88.5142 20.9874 83.5772 11.6554 82.8447C21.1632 70.6853 25.6461 56.5628 24.9429 46.806C35.5201 50.5418 46.1559 51.6991 55.8687 49.7067C54.8432 48.9449 54.0082 48.2564 53.4515 47.6557C44.5737 48.4761 33.9379 46.7474 21.0899 41.7225C22.8626 59.6833 20.3575 69.3815 10 83.5626C19.3906 83.8409 30.627 90.1843 36.1354 96.2347C56.9821 76.0765 80.6124 69.6598 109.502 74.1573C101.694 62.789 102.704 49.5016 110 36.6244C89.4902 44.7258 87.4539 31.2039 86.9851 23Z"
        fill="white"
      />
      <path
        d="M97.7088 64.7667C97.7088 65.4992 97.1228 66.0852 96.3903 66.0852C95.6724 66.0852 95.0718 65.4992 95.0718 64.7667C95.0718 64.0489 95.6578 63.4482 96.3903 63.4482C97.1228 63.4482 97.7088 64.0489 97.7088 64.7667ZM97.5183 64.7667C97.5183 65.382 97.0202 65.8948 96.3903 65.8948C95.775 65.8948 95.2769 65.3967 95.2769 64.7667C95.2769 64.1514 95.775 63.6533 96.3903 63.6533C97.0202 63.6533 97.5183 64.1368 97.5183 64.7667ZM95.731 65.3527L96.068 65.3088L96.1998 64.8546C96.1998 64.8546 96.2731 64.8546 96.3317 64.8546L96.4928 65.2502L96.8737 65.2062L96.6686 64.7521C96.8298 64.6788 96.9763 64.5323 97.0056 64.4005C97.0935 64.1075 96.903 63.917 96.6247 63.9317C96.317 63.9463 96.1266 64.0049 96.1266 64.0049L95.731 65.3527ZM96.6833 64.4005C96.654 64.503 96.4928 64.6349 96.2877 64.6202L96.3903 64.254C96.6686 64.1661 96.7126 64.3126 96.6833 64.4005Z"
        fill="white"
      />
      <path
        d="M81.8576 39.906C82.7366 39.0856 83.6302 38.2506 84.5239 37.4448C84.2748 40.155 84.0258 42.8653 83.7914 45.5755C85.5786 44.9895 87.3659 44.3742 89.1532 43.7736C89.08 44.8284 88.6258 45.8538 87.908 46.6303C86.6627 47.7437 84.9633 48.1099 83.4398 48.6666C82.8831 54.0724 82.2385 59.4782 81.5499 64.8694C79.5136 65.4261 77.4626 65.9681 75.4409 66.5248C76.2174 61.2362 76.9938 55.9476 77.7703 50.659C76.9352 50.9374 76.1148 51.2303 75.2798 51.5233C75.4116 50.6297 75.5435 49.7361 75.6753 48.8424C78.5614 46.557 80.7295 43.4073 81.8576 39.906Z"
        fill="white"
      />
      <path
        d="M68.8779 52.2118C70.8556 51.3035 72.8187 50.4099 74.7964 49.5162C73.8295 55.5666 72.8626 61.5877 71.9104 67.6381C69.9033 68.3706 67.911 69.1471 65.9039 69.8796C66.9001 63.9903 67.8963 58.1011 68.8779 52.2118Z"
        fill="white"
      />
      <path
        d="M63.6186 54.4678C64.7027 53.3984 66.1677 52.7538 67.6766 52.578C67.325 54.4385 66.9734 56.2991 66.6218 58.1596C65.6256 58.1743 64.6148 58.4086 63.7944 58.98C62.7689 59.6685 62.1682 60.8698 62.0217 62.0858C61.4943 65.4992 60.9523 68.9273 60.4249 72.3407C58.4179 73.3955 56.3962 74.4503 54.3745 75.4904C54.9898 71.9305 55.5758 68.3706 56.1764 64.8106C56.5134 62.9355 56.7771 61.0017 56.5134 59.0972C56.3669 58.3061 56.1618 57.4271 55.5025 56.8997C56.528 56.5481 57.5535 56.1672 58.579 55.8449C59.6338 56.3284 60.5275 57.2074 60.967 58.2768C61.6848 56.8997 62.4905 55.5373 63.6186 54.4678Z"
        fill="white"
      />
      <path
        d="M30.3633 57.2954C33.7035 53.9259 38.9775 52.8711 43.475 54.131C43.1087 56.0209 42.7278 57.8814 42.3469 59.7566C41.2628 58.4674 39.71 57.5005 37.9959 57.4126C36.4284 57.3833 34.4067 58.0425 33.9672 59.7419C33.6889 61.1483 34.6704 62.3935 35.6666 63.2579C37.0144 64.342 38.3329 65.5579 39.124 67.1108C39.9151 68.6197 40.0616 70.407 39.6807 72.0625C38.7138 76.3402 35.6519 80.1199 31.6086 81.8925C28.7079 83.1964 25.4117 83.548 22.2913 82.9327C22.9505 80.574 23.639 78.2301 24.3129 75.8714C26.4958 78.0836 30.4659 78.0543 32.5901 75.7982C33.7035 74.6555 34.2749 72.7803 33.4545 71.3153C32.7366 69.9822 31.4328 69.1471 30.2022 68.3267C28.7519 67.3306 27.7703 65.6605 27.741 63.8732C27.7557 61.4706 28.6933 59.0387 30.3633 57.2954Z"
        fill="white"
      />
      <path
        d="M48.368 59.3757C49.9355 58.8337 51.796 58.5993 53.305 59.4636C55.3413 60.6796 55.6929 63.4191 55.1948 65.5433C54.5502 68.5319 53.1145 71.374 50.917 73.5275C49.2616 75.1683 47.2106 76.311 45.0278 77.1167C44.852 77.19 44.7202 76.9849 44.8667 76.8384C45.5991 76.1498 46.1851 75.3294 46.8297 74.5676C48.1189 72.7657 48.9247 70.6708 49.6425 68.5905C50.1699 66.9937 50.4336 65.2796 50.2871 63.5949C50.2432 63.0235 50.0088 62.4083 49.4667 62.1446C48.8221 61.8369 48.0457 62.2032 47.6794 62.7599C47.152 63.5216 46.9323 64.4446 46.7858 65.3382C45.8042 70.9345 44.8081 76.5307 43.8265 82.127C41.8488 83.548 39.8564 84.9398 37.864 86.3461C38.8309 81.0722 39.7978 75.7982 40.7647 70.5389C41.087 68.7077 41.5265 66.8765 41.5265 65.0159C41.5997 63.9318 41.2188 62.6134 40.0468 62.2911C42.8303 61.3095 45.5991 60.3573 48.368 59.3757Z"
        fill="white"
      />
      <path
        d="M94.3686 45.0627C95.2915 44.9455 96.317 45.092 97.0202 45.7366C97.7234 46.3665 97.9432 47.3627 97.8699 48.2857C97.7527 49.8971 96.903 51.3328 95.8629 52.5341C94.2807 54.1896 92.1858 55.2883 90.0322 56.0208C89.6807 56.138 89.666 55.8303 89.8125 55.7131C91.966 54.0431 93.431 51.5672 93.9584 48.901C94.0903 48.3736 94.0903 47.3627 93.4164 47.3627C92.7278 47.3627 92.0979 48.3443 91.673 49.0182C90.5743 51.0252 90.0469 53.2813 89.6074 55.508C89.417 56.5628 89.373 57.6909 89.8418 58.6871C90.1202 59.3024 90.7208 59.7712 91.4093 59.8005C92.4202 59.8444 93.3578 59.3317 94.1342 58.7164C95.3941 57.6909 96.3463 56.3431 97.1228 54.9221C97.3572 55.1711 97.5769 55.4202 97.7967 55.6692C96.6833 58.3941 94.8667 60.9431 92.303 62.4374C90.8087 63.3311 89.0068 63.9464 87.2634 63.5948C85.9303 63.3604 84.7729 62.4228 84.2456 61.1922C83.5131 59.4928 83.6889 57.5444 84.0844 55.7864C84.7583 52.8564 86.2379 50.0876 88.4647 48.0513C90.1201 46.557 92.1418 45.3557 94.3686 45.0627Z"
        fill="white"
      />
    </svg>
  )
}

SpriteRoundedSVG.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

SpriteRoundedSVG.defaultProps = {
  width: 120,
  height: 120,
}
