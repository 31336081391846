import styled, { css } from "styled-components"
import { lighten } from "polished"

interface StyledMenuProps {
  open: boolean;
}

interface StyledBurgerProps {
  open: boolean;
}

export const Container = styled.div``;

export const StyledMenu = styled.nav<StyledMenuProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  height: 100vh;
  text-align: left;
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  ${(props) => props.open ? css`transform: translateX(-100)` : css`transform: translateX(100%)`};

  div {
    display: flex;
    flex-direction: column;
    a {
      font-size: 1rem;
      text-transform: uppercase;
      padding: 1rem 0;
      font-weight: bold;
      letter-spacing: 0.5rem;
      margin-right: 20px;
      margin-left: 20px;
      color: #666666;
      text-decoration: none;
      transition: color 0.3s linear, margin-left 2s, margin-right 2s;

      &:hover {
        color: #e61b2b;
        margin-right: 10px;
        margin-left: 30px;
      }
    }
  }
`

export const StyledBurger = styled.button<StyledBurgerProps>`
  ${(props) => props.open ? 
    css`
      position: absolute;
      top: 30%;
    ` : 
    css`
      position: relative;
      top: 0;
      
      `};
  right: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 5rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  @media (min-width: 601px) {
    &:hover {
    span {
      color: ${(props) =>
        props.open ? lighten(0.1, "#005396") : "rgba(255,255,255,0.6)"};
    }
    div {
      div {
        background-color: ${(props) =>
          props.open ? lighten(0.1, "#005396") : "rgba(255,255,255,0.6)"};
        }
      }
    }
  }

  span {
    margin-right: 10px;
    color: ${(props) => props.open ? "#666666" : "#e9e9e9"};
    font-weight: 600;
    letter-spacing: 1px;
  }

  div {
    display: flex;
    flex-direction: column;
    height: 2rem;
    width: 2rem;
    justify-content: space-around;

    div {
      width: 2rem;
      height: 0.25rem;
      ${(props) => props.open ? css`background-color: #666666` : css`background-color: #e9e9e9`};
      border-radius: 10px;
      transition: all 0.2s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
        ${(props) => props.open && css`transform: rotate(45deg)`};
      }

      :nth-child(2) {
        ${(props) => props.open ? 
          css`
            opacity: 0;
            transform: translateX(20px);
            ` : 
          css`
            opacity: 1;
            transform: translateX(0);
            `};
      }

      :nth-child(3) {
        ${(props) => props.open && css`transform: rotate(-45deg)`};
      }
    }
  }
  `