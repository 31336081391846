import styled from "styled-components"

export const Container = styled.div`
  background-color: #e61c2b;
  position: fixed;
  top: 45px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
`
export const Content = styled.div`
  max-width: 1300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;

  img {
    height: 40px;
  }
`
export const Menu = styled.ul`
  list-style: inline;
  display: flex;

  li {
    a {
      color: #ffffff;
      font-size: 18px;
      margin: 30px 0 30px 30px;
    }
  }
`
