import styled from "styled-components"

export const Container = styled.div`
  margin-top: 80px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TitleBox = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  margin: 20px;

  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  span {
    font-size: 24px;
    color: #686868;
  }
`

export const Content = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 20px 70px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  ${({ isMobile }) =>
    isMobile ? "@media (max-width: 1300px)" : "@media (max-width: 900px)"} {
    margin: 10px 20px;
  }
`

export const BodyText = styled.p`
  color: #686868;
  font-size: 18px;
  line-height: 40px;
  text-align: justify;
  margin: 20px 40px;

  ${({ isMobile }) =>
    isMobile ? "@media (max-width: 1300px)" : "@media (max-width: 900px)"} {
    line-height: 25px;
    margin: 10px 20px;
  }
`
