import React, { useState } from "react"
import PropTypes from "prop-types"
import { useMobileScreen } from '../../../../hooks/screen';
import { MdClose } from "react-icons/md"

import Modal from "@material-ui/core/Modal"

import {
  Container,
  BannerText,
  Button,
  ButtonAlign,
  FormContent,
} from "./styles"
import ButtonSVG from "../../../SVG/ButtonSVG"

export default function Banner({
  phrase,
  name,
  color,
  image,
  body,
  textColor,
}) {
  const { isSmallScreen } = useMobileScreen()

  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Container isMobileView={isSmallScreen}>
      {console.log(isSmallScreen)}
      <img src={image} alt="product" />

      <div>
        <BannerText textColor={textColor} isMobileView={isSmallScreen}>
          <h2>{phrase}</h2>
          <h1>{name}</h1>
          <h2>{body}</h2>
        </BannerText>

        <ButtonAlign isMobileView={isSmallScreen}>
          <Button color={color} onClick={handleOpen}>
            <ButtonSVG
              color={textColor}
              width={isSmallScreen ? 290 : 350}
              height={isSmallScreen ? 50 : 60}
              textColor={color}
            />
          </Button>
        </ButtonAlign>
      </div>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={isOpen}
        onClose={handleClose}
      >
        <FormContent isMobileView={isSmallScreen}>
          <button type="button" onClick={handleClose}>
            <MdClose color="#000" size={48} />
          </button>
          <iframe
            title="monday"
            src="https://forms.monday.com/forms/embed/0a660cf548a3d5edc79047b9ffbedf89"
            width={isSmallScreen ? "100%" : "70%"}
            height={isSmallScreen ? "100%" : "90%"}
            style={{
              border: 0,
              boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)",
            }}
          />
        </FormContent>
      </Modal>
    </Container>
  )
}

Banner.propTypes = {
  phrase: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.string,
  body: PropTypes.string,
  textColor: PropTypes.string,
}

Banner.defaultProps = {
  phrase: "",
  name: "",
  color: "",
  image: "",
  body: "",
  textColor: "",
}
