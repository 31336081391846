import React, { useState } from "react"
import { Container, StyledMenu, StyledBurger } from "./styles"

interface MenuBurgerDTO {
  open: boolean;
  switchOpen: boolean;
}


const MenuBurger = () => {
  const [ open, setOpen ] = useState(false);
  
  return (
    <Container onClick={() => setOpen(!open)}>
      <StyledBurger open={open}>
        <span>MENU</span>
        <div>
          <div />
          <div />
          <div />
        </div>
      </StyledBurger>

      <StyledMenu open={open} >
        <div>
          <a href="#portfolio" >
            PORTIFÓLIO
          </a>
          <a href="#history" >
            A EMPRESA
          </a>
          <a href="#trabalhe-conosco" >
            TRABALHE CONOSCO
          </a>
          <a
            href="https://tawk.to/chat/595e92ea1dc79b329518d044/default"
          >
            CONTATO
          </a>
        </div>
      </StyledMenu>

    </Container>
  )
}
export default MenuBurger;
