import React, { useState } from "react"
import PropTypes from "prop-types"

import { useMobileScreen } from '../../../hooks/screen';

import {
  Container,
  Content,
  DetailStyled,
  ContentContainer
} from "./styles"
import MapaSVG from "../../SVG/MapaSVG"
import DetailSVG from "../../SVG/DetailSVG"
import u from "../../../data/unity.json"

const CompanyHouse: React.FC = () => {
  const [house, setHouse] = useState(1)

  const { isSmallScreen } = useMobileScreen()

  const currentHouse = u.unity[house - 1]

  function callbackState(houseName: number) {
    setHouse(houseName)
  }
  return (
    <Container>
          <strong>Nossas Unidades</strong>
      <Content>
        <ContentContainer>
          <div>
            <strong>{currentHouse.name}</strong>
            <span>{currentHouse.street}</span>
            <span>{currentHouse.neighborhood}</span>
            <span>{currentHouse.phone1}</span>
            <span>{currentHouse.phone2}</span>
          </div>
          <MapaSVG callbackState={callbackState} />
          </ContentContainer>
      </Content>
    </Container>
  )
}

export default CompanyHouse;

CompanyHouse.propTypes = {
  isMobileView: PropTypes.bool,
}

CompanyHouse.defaultProps = {
  isMobileView: false,
}
