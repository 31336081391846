import React, { createContext, useContext } from 'react';


interface MobileScreenContextData {
  isSmallScreen: boolean | undefined;
}

const MobileScreenContext = createContext<MobileScreenContextData>({} as MobileScreenContextData)

export const MobileScreenProvider: React.FC = ({ children }) => {
  const isSmallScreen = typeof window !== 'undefined' && window.matchMedia('(max-width: 600px)').matches

  return (
    <MobileScreenContext.Provider value={{ isSmallScreen }}>
      {children}
    </MobileScreenContext.Provider>
  );
}

export function useMobileScreen(): MobileScreenContextData {
  const context = useContext(MobileScreenContext);

  return context;
}

