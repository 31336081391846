import React from "react";

import { useMobileScreen } from '../../../hooks/screen';


import {
  Container,
  Content,
  TruckBar,
  TitleBox,
  Body,
  TruckTitleDiv,
} from "./styles"

export default function Workspace() {

  const { isSmallScreen } = useMobileScreen()

  return (
    <Container id="trabalhe-conosco">
      {console.log(isSmallScreen)}
      <TruckTitleDiv>
        <TitleBox isMobileView={isSmallScreen}>
          <strong>Trabalhe Conosco</strong>
        </TitleBox>
        <TruckBar isMobileView={isSmallScreen} />
      </TruckTitleDiv>
      <Content>
        <Body isMobileView={isSmallScreen}>
          <p>
            Venha fazer parte da nossa equipe. Clique no botão abaixo e deixe o
            seu currículo.
          </p>
          <a href="https://jobs.solides.com/vvc">Faça parte do nosso time!</a>
        </Body>
      </Content>
    </Container>
  )
}