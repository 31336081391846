import styled from "styled-components"

const mobileMaxWidth = "@media (max-width: 1300px)"
const desktopMinWidth = "@media (max-width: 900px)"

export const Container = styled.section`
  height: 100%;
  position: relative;
`

export const TruckTitleDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 2;

  ${({ isSmallScreen }) => (isSmallScreen ? mobileMaxWidth : desktopMinWidth)} {
    position: absolute;
    width: 100%;
    top: -50%;
    margin-top: 50px;
    right: 0;
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const TruckBar = styled.div`
  background: url("assets/truckBar.svg") no-repeat;
  width: 60%;
  height: 238px;

  z-index: 2;

  ${({ isSmallScreen }) => (isSmallScreen ? mobileMaxWidth : desktopMinWidth)} {
    width: 100%;
    height: 151px;
    position: relative;
    background: url("assets/truckBarSmall.svg") no-repeat;
    top: 0;
    right: 0;
  }
`

export const Content = styled.div`
  background: #f9f9f9;
  height: 100%;
  position: relative;
  z-index: 1;
  box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;

  flex-direction: column;

  ${({ isSmallScreen }) => (isSmallScreen ? mobileMaxWidth : desktopMinWidth)} {
    padding-top: 10%;
  }
`

export const TitleBox = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  strong {
    font-size: 24px;
    color: #005396;
    letter-spacing: 1px;
  }

  ${({ isSmallScreen }) => (isSmallScreen ? mobileMaxWidth : desktopMinWidth)} {
    margin-top: 30px;
    margin-bottom: 0;

    strong {
      width: 100%;
    }
  }
`

export const Body = styled.div`
  height: 100%;
  max-width: 400px;
  margin-top: 50px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  align-items: center;

  p {
    color: #686868;
    font-size: 18px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }

  a {
    background-color: #888888;
    width: 18rem;
    height: 3.75rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
    margin-top: 30px;
    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color 2s;

    &:hover {
      background-color: #e61c2b;
      transition: 0.3s;
    }
  }

  ${({ isSmallScreen }) => (isSmallScreen ? mobileMaxWidth : desktopMinWidth)} {
    margin: 30px;
    margin-top: 90px;
  }
`
