import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  height: 600px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    height: 100%;

    div {
      display: flex;
      flex-direction: column;

      justify-content: center;

      align-items: center;
    }
  }

  img {
    height: auto;
    max-height: 600px;
    width: 100%;
    max-width: 600px;
  }

  div {
    width: 100%;
  }
`

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  background: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
`

export const BannerText = styled.div`
  width: 450px;
  height: 100%;
  margin-bottom: 30px;

  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;

  h1 {
    color: ${({ textColor }) => textColor};
    font-size: 44px;
    line-height: 3rem;
    letter-spacing: 2px;
    font-weight: 500;

    text-align: center;
  }

  h2 {
    color: ${({ textColor }) => textColor};
    margin: 15px 0;
    font-size: 32px;
    letter-spacing: 2px;
    text-align: center;
    font-weight: 500;
  }

  @media (max-width: 600px) {
    width: 100%;

    h1 {
      font-size: 36px;
      margin: 0 10%;
      width: 80%;
    }
    h2 {
      font-size: 24px;
      margin: 0 10%;
      width: 80%;
    }
  }
`

export const ButtonAlign = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;
  }
`

export const FormContent = styled.div`
  position: absolute;
  width: 70%;
  height: 90%;
  left: 15%;
  top: 10%;

  button {
    margin: 0;
    padding: 0;
    background: none;

    position: absolute;
    left: 10px;
    top: 10px;
  }

  @media (max-width: 600px) {
    top: 10%;
    left: 0;
  }
`
