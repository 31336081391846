import styled from 'styled-components';


export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  strong {
    font-size: 24px;
    color: #005396;
    letter-spacing: 1px;
  }
`;

export const Content = styled.div`
  background: #ff8104;
  background-image: url('assets/detail.svg');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 10%;
  margin: 30px 0;
  width: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  
`;


export const DetailStyled = styled.div`
  position: relative;
  left: 0;
  top: 0;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1120px;
  width: 100%;

  div {
    flex: 1;
    max-width: 800px;
    min-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;


    strong {
      margin-bottom: 16px;
      font-size: 20px;
      color: #fff;
      letter-spacing: 1px;
    }
  }

  svg {
    margin: 30px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: none;
    justify-content: none;
    background-size: 15%;

    div {
      min-width: none;

      strong {
        margin-top: 30px;
      }
    }

    svg {
        margin: 30px 0;
      }
    

  }
`;