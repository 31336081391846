import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  max-height: 600px;
  height: 100%;
  justify-content: center;
  background: ${({ color }) => color};
  margin-top: 125px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);

  @media( max-width: 600px) {
    flex-direction: column;
    max-height: none;
  }
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 1300px;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;

    div {
      display: flex;
    }
  }
`

export const MainBanner = styled.div`
  width: 100%;
  display: flex;
  max-width: 1300px;
  background: ${props => props.background};
`

export const NextButton = styled.button`
  border: none;
  margin: 0;
  margin-left: 30px;
  width: 64px;
  height: 64px;
  top: 50%;
  left: 80%;
  background: none;

  @media (max-width: 600px) {
    margin: 30px 0;
  }
`
