import styled from "styled-components"

export const Container = styled.div`
  background-color: #e61c2b;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Icons = styled.div`
  display: flex;
  a + a {
    margin-left: 8px;
  }

  a {
    img {
      width: 48px;
      height: 48px;
    }
  }

  @media (max-width: 600px) {
    margin-top: 30px;

    a {
      img {
        width: 36px;
        height: 36px;
      }
    }
  }
`

export const Content = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-content: center;
    justify-items: center;
  }

  p {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    margin: 0 70px;
    text-align: justify;

    @media (max-width: 600px) {
      font-size: 14px;
      margin: 0;

      text-align: center;
    }
  }
`

export const LogoResize = styled.img`
  height: 100%;
  max-height: 60px;

  @media (max-width: 600px) {
    max-height: 40px;
    margin-bottom: 30px;
  }
`
