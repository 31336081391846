import React from "react"
import { useMobileScreen } from '../../../hooks/screen';

import { Container, TitleBox, Content, BodyText } from "./styles"

export default function History() {

  const { isSmallScreen } = useMobileScreen()

  return (
    <Container id="history">
      <Content>
        <TitleBox>
          <span>A História da VVC</span>
        </TitleBox>

        <BodyText isMobile={isSmallScreen}>
          A VVC Distribuidora é uma empresa de origem curraisnovense, que foi
          fundada no ano de 1995, por Vicente Vilani da Cunha e sua esposa Reusa
          Maria Silva Cunha. Ela representa a Coca-Cola (Solar) e a Cervejaria
          Heineken em vários municípios do interior do Rio Grande do Norte. Após
          a fundação, A VVC Distribuidora se expandiu abrindo em 2005 uma filial
          na cidade de Pau dos Ferros e mais tarde no ano de 2007 uma segunda
          filial, na cidade de São José de Mipibu. A empresa iniciou atendendo
          em 24 cidades e hoje com as 2 filiais e o auxílio de mais de 200
          colaboradores atende 155 municipios. Com isso ao longo desses anos,
          visou sempre a melhor qualidade no atendimento, executando todos os
          projetos elaborados pela Norsa, e praticando os melhores preços com
          isto possibilitando melhores margens para o desenvolvimento dos
          empreendedores e empresários do interior do Rio Grande do Norte.
        </BodyText>
      </Content>
    </Container>
  )
}
