import React from "react"

import { Container, Content, LogoResize, Icons } from "./styles"

const Footer: React.FC = () => {

  return (
    <Container >
      <Content >
        <LogoResize src="assets/LogoFooter.svg" />

        <p>
          © {new Date().getFullYear()} VVC Distribuidora | Distribuidora de
          Bebidas | Todos os direitos reservados.
        </p>

        <Icons>
          <a
            href="https://api.whatsapp.com/send?phone=558436452416"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/whatsappIcon2.png"
              alt="whatsapp"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/vvc-distribuidora/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/linkedinIcon.png"
              alt="linkedin"
            />
          </a>
          <a
            href="https://www.facebook.com/VVCDistribuidora/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/facebookIcon.png"
              alt="facebook"
            />
          </a>
          <a
            href="https://t.me/vvcdistribuidora"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/telegramIcon.png"
              alt="telegram"
            />
          </a>
        </Icons>
      </Content>
    </Container>
  )
}

export default Footer;