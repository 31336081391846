import React from "react"

import AppProvider from '../hooks';

import DefaultLayout from "../components/DefaultLayout"
import Banners from "../components/Content/Banners"
import Brands from "../components/Content/Brands"
import CompanyHouse from "../components/Content/CompanyHouse"
import History from "../components/Content/History"
import Workspace from "../components/Content/Workspace"

export default () => {
  return (
    <AppProvider>
      <DefaultLayout>
        <Banners />
        <Brands />
        <CompanyHouse />
        <History />
        <Workspace />
      </DefaultLayout>
    </AppProvider>
    
  )
}
