import React from "react"
import PropTypes from "prop-types"

export default function FantaRoundedSVG({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60" cy="60" r="60" fill="#FF8104" />
      <path
        d="M89.5997 59.9497C89.5997 80.1475 73.2261 96.5205 53.0283 96.5205C32.831 96.5205 16.4575 80.1475 16.4575 59.9497C16.4575 39.7524 32.831 23.3789 53.0283 23.3789C73.2261 23.3789 89.5997 39.7524 89.5997 59.9497Z"
        fill="#F8992E"
      />
      <path
        d="M10 43.6651L30.0839 41.5434C30.0839 41.5434 22.8897 35.7215 22.1403 33.0236C22.1403 33.0236 21.8405 31.5248 24.2386 30.7754C26.6367 30.026 36.8286 25.9793 36.8286 25.9793C36.8286 25.9793 51.5168 21.4511 58.4113 39.3024L70.7015 37.3917V39.3024L92.2842 38.7029L93.0336 46.2131H98.4293L104.874 68.5452L76.6967 90.4272C76.6967 90.4272 70.2518 95.9727 56.0132 96.2725L14.7962 79.9361L10 43.6651Z"
        fill="#28409A"
      />
      <path
        d="M24.5996 33.1739C24.5996 33.1739 32.7933 30.0761 33.4929 29.3765C34.1919 28.6774 43.9845 24.9802 50.7789 30.8753C50.7789 30.8753 55.5751 35.5719 57.0739 41.1673L59.9713 47.0624L61.4701 49.8106C61.4701 49.8106 61.87 50.8094 61.4701 51.0096C61.0709 51.2092 57.9731 46.3633 57.9731 46.3633L56.6242 44.3645C56.6242 44.3645 55.4252 42.8159 51.9282 43.5156C48.4306 44.2146 43.135 44.8141 39.2879 44.1649C35.4413 43.5156 26.5984 35.9712 24.5996 33.1739Z"
        fill="#6FBF58"
      />
      <path
        d="M40.1872 44.1639C36.3406 43.5146 27.4977 35.9703 25.4989 33.1729C25.4989 33.1729 33.6925 30.0752 34.3922 29.3755C34.7789 28.9889 37.9533 27.6855 41.8616 27.5968C37.5499 27.4811 33.909 28.9595 33.4929 29.3755C32.7933 30.0752 24.5996 33.1729 24.5996 33.1729C26.5984 35.9703 35.4413 43.5146 39.2879 44.1639C40.5798 44.3822 42.0361 44.4571 43.5319 44.4367C42.355 44.4199 41.2183 44.3378 40.1872 44.1639Z"
        fill="#5F994F"
      />
      <path
        d="M31.7896 35.2711C31.7896 35.2711 35.5366 33.3976 37.5851 35.2711C41.6019 38.9444 50.2752 40.8665 51.9736 40.4666C51.9736 40.4666 43.3069 42.4174 40.7824 41.0661C37.485 39.3017 35.4868 36.8695 31.7896 35.2711Z"
        fill="#5F994F"
      />
      <path
        d="M13.064 46.356L17.7103 78.4304L24.6047 77.9808L23.5556 64.1918L30.4501 63.5923L29.5508 57.597L22.6563 58.4963L22.0568 52.651L30.4501 51.1522L29.251 43.5083L13.064 46.356Z"
        fill="white"
      />
      <path
        d="M48.8794 49.0594L52.1768 75.2884L58.6216 74.539L57.7223 61.3496L68.2139 73.4898L73.1606 72.8903L69.2631 41.1157L62.2187 41.8651L63.7175 55.2045L55.4741 47.7105L48.8794 49.0594Z"
        fill="white"
      />
      <path
        d="M55.4741 48.307L63.7175 55.801L63.6426 55.1332L55.4741 47.7075L48.8794 49.0564L48.9531 49.641L55.4741 48.307Z"
        fill="#C2C2E0"
      />
      <path
        d="M69.2661 41.7152L73.091 72.8987L73.1635 72.8903L69.2661 41.1157L62.2217 41.8651L62.2882 42.4574L69.2661 41.7152Z"
        fill="#C2C2E0"
      />
      <path
        d="M57.7173 61.9531L58.5764 74.5479L58.6166 74.5431L57.7617 62.0041L57.7173 61.9531Z"
        fill="#C2C2E0"
      />
      <path
        d="M71.0596 42.3093L71.9589 49.3537L76.1555 49.5036L78.1039 71.536L86.0476 71.3861L85.2982 48.9041L90.8438 48.4544L89.7946 40.8105L71.0596 42.3093Z"
        fill="white"
      />
      <path
        d="M71.7592 43.0084L77.5044 42.259L89.7946 40.8105L71.0596 42.3093L71.9589 49.3537L72.5716 49.3753L71.7592 43.0084Z"
        fill="#C2C2E0"
      />
      <path
        d="M76.8628 50.2083L76.2231 50.1855L78.1116 71.5417L78.7483 71.5297L76.8628 50.2083Z"
        fill="#C2C2E0"
      />
      <path
        d="M37.5899 64.351L38.3393 59.2551H39.5383L41.3369 63.9014L37.5899 64.351ZM40.6373 47.4146L33.8926 48.164L31.0449 77.3906L36.4406 77.0908L37.7895 69.2971L41.6864 68.8474L44.8339 76.0417L50.5293 75.2923L40.6373 47.4146Z"
        fill="white"
      />
      <path
        d="M34.0054 48.4104L40.6462 47.4391L40.6373 47.4146L33.8926 48.164L31.0449 77.3906L32.1918 77.3264L34.0054 48.4104Z"
        fill="#C2C2E0"
      />
      <path
        d="M45.9047 75.8944L42.5108 68.8135L41.7261 68.9316L44.837 76.0425L50.5324 75.2931L50.4755 75.1318L45.9047 75.8944Z"
        fill="#C2C2E0"
      />
      <path
        d="M94.9941 60.8962C93.3455 60.9958 92.8958 60.0467 92.5961 59.0977L96.4929 58.6978C96.4929 58.6978 96.6428 60.7961 94.9941 60.8962ZM97.1422 49.3555L88.1494 50.7044L88.4492 70.6385L93.2453 70.1888L93.5451 65.0929L96.2429 64.7931L98.3413 68.9898L103.437 68.8399L97.1422 49.3555Z"
        fill="white"
      />
      <path
        d="M89.3257 51.1818L97.4528 50.2927L97.1422 49.3311L88.1494 50.68L88.4492 70.614L88.8431 70.5775L89.3257 51.1818Z"
        fill="#B9BADB"
      />
      <path
        d="M97.3396 59.4995L96.4056 59.5577C96.2653 60.144 95.905 60.8143 94.9907 60.8694C94.4901 60.9 94.11 60.8251 93.8013 60.6914C94.125 61.2471 94.6706 61.6584 95.7545 61.6362C97.4062 61.602 97.3396 59.4995 97.3396 59.4995Z"
        fill="#B9BADB"
      />
      <path
        d="M96.8514 65.5786L96.6577 65.5924L98.3424 68.9617L98.3771 68.9605L96.8514 65.5786Z"
        fill="#B9BADB"
      />
      <path
        d="M14.5532 57.2534L17.9009 78.4369L19.2864 78.3464L15.353 57.8529L14.5532 57.2534Z"
        fill="#C2C2E0"
      />
      <path
        d="M106.894 66.2516L106.371 66.3427L106.338 66.1538L107.611 65.9326L107.644 66.1215L107.118 66.2126L107.383 67.7426L107.159 67.7821L106.894 66.2516Z"
        fill="#28409A"
      />
      <path
        d="M109.589 66.5812C109.535 66.3438 109.47 66.0585 109.435 65.8432L109.427 65.845C109.404 66.0573 109.369 66.2833 109.324 66.5357L109.165 67.4224L108.997 67.4517L108.577 66.6814C108.453 66.4529 108.345 66.2431 108.262 66.0477L108.257 66.0483C108.288 66.2641 108.326 66.5537 108.356 66.8144L108.438 67.5597L108.226 67.5962L108.047 65.8564L108.33 65.8072L108.768 66.5878C108.876 66.7869 108.968 66.9649 109.041 67.137L109.05 67.1352C109.063 66.9541 109.091 66.7551 109.131 66.5249L109.291 65.6406L109.575 65.5908L109.981 67.2911L109.764 67.3288L109.589 66.5812Z"
        fill="#28409A"
      />
    </svg>
  )
}

FantaRoundedSVG.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

FantaRoundedSVG.defaultProps = {
  width: 120,
  height: 120,
}
