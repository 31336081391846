import styled, { createGlobalStyle } from "styled-components"

export const Container = styled.div``

export const GlobalStyle = createGlobalStyle`
  @font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: local('Roboto'), url('/public/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SF Pro Text';
    font-weight: bold;
    src: local('SF Pro Text'), url('/public/fonts/SF-Pro-Text-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'SF Pro Text';
    font-weight: 400;
    src: local('SF Pro Text'), url('/public/fonts/SF-Pro-Text-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'SF Pro Text';
    font-weight: 500;
    src: local('SF Pro Text'), url('/public/fonts/SF-Pro-Text-Semibold.otf') format('opentype');
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
*:focus {
  outline: 0;
}
html, body, #root {
  height: 100%;
  scroll-padding-top: 125px;
  overflow: auto;
  background-color: #F5F5F5;
}
body {
  -webkit-font-smoothing: antialiased;
}
body, input, button {
  font: 14px 'Roboto', sans-serif;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
button {
  cursor: pointer;
  border: 0;
  padding: 0;
}
`
