import React, { useState, useCallback, useEffect } from "react"

import { Container, Content } from "./styles"

import MenuBurger from "../Menu"


const Header: React.FC = () => {
  

  return (
    <Container>
      <Content>
        <a href="/">
          <img src="assets/LogoFooter.svg" alt="Logo VVC" />
        </a>

        <div>
          <MenuBurger />
        </div>
      </Content>
    </Container>
  )
}
export default Header;